import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SINRequestService } from '@shared/services/sin-request.service';

@Component({
  selector: 'app-meralco-online-view',
  templateUrl: './meralco-online-view.component.html',
  styleUrls: ['./meralco-online-view.component.scss']
})
export class MeralcoOnlineViewComponent implements OnInit {
  @Input() userEmail
  @Input() moDatas
  // @Input() ipaDatas
  // @Input() totalBillAmount
  // @Input() totalPaidAmount
  // @Input() totalBalance
  // @Input() totalBalanceNoInstallment
  constructor(
    public activeModal: NgbActiveModal,
    private sinRequestService: SINRequestService,
  ) { }  
  bphDatas = [];
  ipaDatas = [];
  totalBillAmount = 0;
  totalPaidAmount = 0;
  totalBalance = 0;
  totalBalanceNoInstallment = 0;
  loading = true

  ngOnInit() {
    this.getData()
  }
  
  getData(){
    // this.bphDatas = [];
    // this.ipaDatas = [];
    // this.totalBillAmount = 0
    // this.totalPaidAmount = 0
    // this.totalBalance = 0
    // this.totalBalanceNoInstallment = 0
    this.bphDatas = this.moDatas['bph']
    this.ipaDatas = this.moDatas['ipa']
    this.totalBalance = this.moDatas['balance_w_installments']
    this.totalBalanceNoInstallment = this.moDatas['balance_wo_installments']

    if(this.bphDatas.length > 0){
      this.bphDatas.forEach(data => {
          this.totalBillAmount += data['total_amount'] ? parseFloat(data['total_amount']) : 0
          this.totalPaidAmount += parseFloat(data['payment_or_credit'] == "" || data['payment_or_credit'] == undefined ? 0 : data['payment_or_credit'])
          if (data['billing_date'].length > 8){
            data['billing_date_parsed'] = data['billing_date']  == '' ? null : data['billing_date'].toString().substr(0,2) + '/' + data['billing_date'].toString().substr(3,2) + '/' + data['billing_date'].toString().substr(6,4)
          }else{
            data['billing_date_parsed'] = data['billing_date']  == '' ? null : data['billing_date'].toString().substr(4,2) + '/' + data['billing_date'].toString().substr(6,2) + '/' + data['billing_date'].toString().substr(0,4)
          }
          data['payments'].forEach(payment => {
            if (payment['payment_or_credit_date'].length > 8){
              payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(0,2) + '/' + payment['payment_or_credit_date'].toString().substr(3,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,4)
            }else{
              payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(4,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,2) + '/' + payment['payment_or_credit_date'].toString().substr(0,4)
            }
          });
          this.loading = false
        });
    }

    if(this.ipaDatas.length > 0){
      this.ipaDatas.forEach(data => {
        this.totalBillAmount += data['billing_information']['total_amount'] ? parseFloat(data['billing_information']['total_amount']) : 0
        this.totalPaidAmount += parseFloat(data['billing_information']['payment_or_credit'] == "" || data['billing_information']['payment_or_credit'] == undefined ? 0 : data['billing_information']['payment_or_credit'])
        if (data['billing_information']['billing_date'].length > 8){
          data['billing_information']['billing_date_parsed'] = data['billing_information']['billing_date']  == '' ? null : data['billing_information']['billing_date'].toString().substr(0,2) + '/' + data['billing_information']['billing_date'].toString().substr(3,2) + '/' + data['billing_information']['billing_date'].toString().substr(6,4)
        }else{
          data['billing_information']['billing_date_parsed'] = data['billing_information']['billing_date']  == '' ? null : data['billing_information']['billing_date'].toString().substr(4,2) + '/' + data['billing_information']['billing_date'].toString().substr(6,2) + '/' + data['billing_information']['billing_date'].toString().substr(0,4)
        }
        data['billing_information']['payments'].forEach(payment => {
          if (payment['payment_or_credit_date'].length > 8){
            payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(0,2) + '/' + payment['payment_or_credit_date'].toString().substr(3,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,4)
          }else{
            payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(4,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,2) + '/' + payment['payment_or_credit_date'].toString().substr(0,4)
          }
        });
        data['ipa_list'].forEach(ip => {
          ip['ipas'].forEach(element => {
            element['paidAmount'] = element['amount'] - element['due_amount']
          });
        });
        this.loading = false
      });
    }
  }

}
