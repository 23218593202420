<div class="modal-content">
    <label class="close" (click)="activeModal.dismiss('cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </label>
    <div class="modal-header header">Billing & Payments History</div> 
    <hr>
    <ng-container *ngIf="loading">
        <div class="container">
            <app-loading-component></app-loading-component>
        </div>
    </ng-container>
    <div class="history-table" *ngIf="!loading">
        <div class="customerDiv">
            <div class="customerInfo">
                <label>Customer Account No. (CAN):</label><span> {{this.CAN}}</span><br/>
                <label>Service Identification No. (SIN):</label><span> {{this.searchSIN}}</span><br/>
            </div>
            <div class="tableFilter">
                <div class="selectIPA">
                    <label class="viewIpaLbl">View IPA entries based on</label>
                    <select [(ngModel)]="ipaValue" class="selectIPAValue" (change)="getData()">
                        <option value="MERALCO_ONLINE">Default</option>
                        <option value="AFFECTED_BILL">Affected Bill</option>
                        <option value="DUE_DATE">Due Date</option>
                    </select>
                    <label class="yearLbl">Year</label>
                    <select class="selectedYear" [(ngModel)]="viewYear" (change)="changeYear()">
                        <option *ngFor="let year of yearsData" value="{{year}}">{{year}}</option>
                    </select>
                </div>
                <div class="excelbtndiv">
                    <span class="exportExcelBtn" (click)="exportIPA()">
                    <img class="exportImg" src="../../../../assets/img/export.svg" alt="export_excel">
                    Export to Excel</span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="ipaValue == 'MERALCO_ONLINE'">
            <app-meralco-online-view [userEmail]="userEmail" [moDatas]="bphDatas"></app-meralco-online-view>
        </ng-container>
        <ng-container *ngIf="ipaValue == 'AFFECTED_BILL'">
            <app-affected-bill-view [userEmail]="userEmail" [abDatas]="bphDatas"></app-affected-bill-view>
        </ng-container>
        <ng-container *ngIf="ipaValue == 'DUE_DATE'">
            <app-due-date-view [userEmail]="userEmail" [ddDatas]="bphDatas"></app-due-date-view>
        </ng-container>
        <!-- <div class="bill-table">
            <ng-container>
                <table *ngIf="ipaValue == 'MERALCO_ONLINE'" id="moTbl">
                    <thead>
                        <tr>
                            <th>Bill Month</th>
                            <th>Bill Amount</th>
                            <th>Bill Status</th>
                            <th>Paid Amount</th>
                            <th>Payment Date</th>
                            <th>Payment Channel</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let bphData of bphDatas">
                            <ng-container *ngIf="bphData.payments.length == 0">
                                <tr>
                                    <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                                    <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                                    <td>{{bphData.recv_status}}</td>
                                    <td>0.00</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="bphData.payments.length == 1">
                                <tr>
                                    <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                                    <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                                    <td>{{bphData.recv_status}}</td>
                                    <td>{{bphData.payments[0].payment_or_credit_amount | number : '1.2-2'}}</td>
                                    <td>{{bphData.payments[0].payment_or_credit_date_parsed}}</td>
                                    <td>{{bphData.payments[0].payment_channel}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="bphData.payments.length > 1">
                                <tr>
                                    <td>{{bphData.billing_date_parsed | date :'MMMM'}}</td>
                                    <td>{{bphData.total_amount | number : '1.2-2'}}</td>
                                    <td>{{bphData.recv_status}}</td>
                                    <td>{{bphData.payment_or_credit | number : '1.2-2'}}</td>
                                    <td colspan="2"> </td>
                                </tr>
                                <tr *ngFor="let payment of bphData.payments">
                                    <td *ngIf="idx!=0" colspan="2"></td>
                                    <td>{{bphData.recv_status}}</td>
                                    <td>{{payment.payment_or_credit_amount | number : '1.2-2'}}</td>
                                    <td>{{payment.payment_or_credit_date_parsed}}</td>
                                    <td>{{payment.payment_channel ? payment.payment_channel : '-'}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let ipaData of ipaDatas">
                            <ng-container *ngIf="ipaData.billing_information.payments.length == 0">
                                <tr class="ipaMonthRow">
                                    <td>{{ipaData.billing_information.billing_date_parsed | date :'MMMM'}}</td>
                                    <td>{{ipaData.billing_information.total_amount | number : '1.2-2'}}</td>
                                    <td>{{ipaData.billing_information.recv_status}}</td>
                                    <td>0.00</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="ipaData.billing_information.payments.length == 1">
                                <tr class="ipaMonthRow">
                                    <td>{{ipaData.billing_information.billing_date_parsed | date :'MMMM'}}</td>
                                    <td>{{ipaData.billing_information.total_amount | number : '1.2-2'}}</td>
                                    <td>{{ipaData.billing_information.recv_status}}</td>
                                    <td>{{ipaData.billing_information.payments[0].payment_or_credit_amount | number : '1.2-2'}}</td>
                                    <td>{{ipaData.billing_information.payments[0].payment_or_credit_date_parsed}}</td>
                                    <td>{{ipaData.billing_information.payments[0].payment_channel}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="ipaData.billing_information.payments.length > 1">
                                <tr class="ipaMonthRow">
                                    <td>{{ipaData.billing_information.billing_date_parsed | date :'MMMM'}}</td>
                                    <td>{{ipaData.billing_information.total_amount | number : '1.2-2'}}</td>
                                    <td>{{ipaData.billing_information.recv_status}}</td>
                                    <td>{{ipaData.billing_information.payment_or_credit | number : '1.2-2'}}</td>
                                    <td colspan="2"> </td>
                                </tr>
                                <tr class="ipaMonthRow" *ngFor="let payment of ipaData.billing_information.payments">
                                    <td *ngIf="idx!=0" colspan="2"></td>
                                    <td>{{ipaData.billing_information.recv_status}}</td>
                                    <td>{{payment.payment_or_credit_amount | number : '1.2-2'}}</td>
                                    <td>{{payment.payment_or_credit_date_parsed}}</td>
                                    <td>{{payment.payment_channel ? payment.payment_channel : '-'}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let ipa of ipaData.ipa_list">
                                <ng-container *ngFor="let i of ipa.ipas">
                                    <tr class="ipaRow" *ngIf="ipaDatas.length > 0">
                                        <td style="text-align: right;">IPA {{i.order_number}} of {{ipa.ipas.length}}</td>
                                        <td>{{i.amount | number : '1.2-2'}}</td>
                                        <td>{{i.status}}</td>
                                        <td>{{i.paidAmount | number : '1.2-2'}}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{{i.due_date_parsed}}</td>
                                        <td>{{ipa.paymentChannel}}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <tr class="totalRow">
                            <td class="totalLbl">
                                TOTAL
                            </td>
                            <td>
                                {{totalBillAmount | number : '1.2-2'}}
                            </td>
                            <td>
    
                            </td>
                            <td>
                                {{totalPaidAmount | number : '1.2-2'}}
                            </td>
                            <td>
                               
                            </td>
                            <td>
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div> -->
        <!-- <div class="balanceDiv">
            <label class="balLbl">Balance including Installments:</label><span> {{totalBalance | number : '1.2-2'}}</span><br/>
            <label class="balLbl">Balance excluding Installments:</label><span> {{totalBalanceNoInstallment | number : '1.2-2'}}</span>
        </div> -->
    </div>
</div>


