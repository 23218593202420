import { Component, OnInit, Input } from '@angular/core';
import { PaymentHistoryService } from "@shared/services/payment-history.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {

  @Input() statement_number;

  /*name of the excel-file which will be downloaded. */ 
  fileName= 'Payment History.xlsx';  

  exportPH(): void 
    {
        /* table id is passed over here */   
        let element = document.getElementById('historyTbl'); 
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
        /* save to file */
        XLSX.writeFile(wb, this.fileName);
      
    }

  constructor(
    public activeModal: NgbActiveModal,
    private paymentHistoryService: PaymentHistoryService,
  ) {}
  

  history_record =[]
  totalAmount:number

  ngOnInit() {
    let userEmail = localStorage.getItem('userEmail');
    this.paymentHistoryService.getPaymentHistory(
      this.statement_number,
      userEmail
    ).subscribe(
      resp => {
        console.log(resp)
 
        if ( resp['status'] == 200 ) { 
          this.totalAmount = 0   
          let dateFormat = ''
          resp['body']['results'].forEach(data=>{
            this.totalAmount = this.totalAmount + parseFloat(data['payment_or_credit_amount'])
            dateFormat = data['payment_or_credit_date']  == '' ? null : data['payment_or_credit_date'].toString().substr(4,2) + '/' + data['payment_or_credit_date'].toString().substr(6,2) + '/' + data['payment_or_credit_date'].toString().substr(0,4)
            data['payment_or_credit_date'] = dateFormat
          })   

          this.history_record = resp['body']['results']    
          
          console.log(this.history_record)
        }
      },
      error => {
        console.log(error)
      }
    )
  }
 

}


