import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Hub, Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { AuthService } from '@shared/services/guards/auth.service';
import { RouteService } from '@shared/services/guards/route.service';
import { AdminService } from '@shared/services/admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  prevURL = localStorage.getItem('prev_url')
  constructor(
    private router: Router,
    private routeService: RouteService,
    private authService: AuthService,
    private ngZone: NgZone,
    private adminService: AdminService,
  ){}

  ngOnInit() {
    console.log('Onload '+this.routeService.getPreviousUrl(), 'this.routeService.getPreviousUrl()')
    if(this.routeService.getPreviousUrl() == '/login'){
      
      this.routing()
    }else{
      this.loading = true;
      Hub.listen('auth', (data) => {
        console.log(data)
        switch (data.payload.event) {          
            case 'signIn':
                console.log('now the user is signed in');
                this.routing()
                break;
            case 'signIn_failure':
                console.log('now the user is fail in');
                this.routing()
                break;
            default:
                break;
        }
      });
    }
  }

  // data = user PROFILE
  routing() {
      this.authService.isVerified().subscribe(data => {
        console.log("User Profile " + data['username'].replace('MeralcoAD_',''))
        this.validateUserInPool(data)
        // this.ngZone.run(() => this.router.navigate([environment.homepage]))
      },
      err => {
        this.update(false, '')
      }
    );
  }

  update(isAuthenticated, emailAddress){

    Auth.currentSession().then( data => {
      // let userEmail = data.getIdToken().payload.email;
      // localStorage.setItem('userType',userType)
      
      localStorage.setItem('userEmail',emailAddress)

      if (isAuthenticated){
        if (this.prevURL != undefined && this.prevURL != '/sin-inquiry/view') {
          localStorage.removeItem('prev_url')
          this.ngZone.run(() => this.router.navigateByUrl(this.prevURL))
        } else {
          localStorage.removeItem('prev_url')
          this.ngZone.run(() => this.router.navigate([environment.homepage]))
        }
      }
      else {
        // CLEAR STORAGE AND REDIRECT
        Auth.signOut()
        localStorage.clear();
        window.location.replace(environment.signOut);
      }


    }) //then
  }

  validateUserInPool(data){
    let emailAddress = data['username'].replace('MeralcoAD_','')

    this.update(true, emailAddress)
    // this.adminService.getUser(loginEmail).subscribe(profile =>{
    //   let loginUserType = profile['body']['userType']
    //   this.update(true, loginUserType)
    // },
    // error => {
    //     this.update(false, '')
    //   }
    // )
  }

}
