import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SINRequestService } from '@shared/services/sin-request.service';

@Component({
  selector: 'app-affected-bill-view',
  templateUrl: './affected-bill-view.component.html',
  styleUrls: ['./affected-bill-view.component.scss']
})
export class AffectedBillViewComponent implements OnInit {
  @Input() userEmail
  @Input() abDatas

  constructor(
    public activeModal: NgbActiveModal
  ) { }
  
  bphDatas = [];
  ipaDatas = [];
  totalBillAmount = 0;
  totalPaidAmount = 0;
  totalBalance = 0;
  totalBalanceNoInstallment = 0;
  loading = true

  ngOnInit() {
    this.getData()
  }

  getData(){
    // this.bphDatas = [];
    // this.ipaDatas = [];
    // this.totalBillAmount = 0
    // this.totalPaidAmount = 0
    // this.totalBalance = 0
    // this.totalBalanceNoInstallment = 0
    this.bphDatas = this.abDatas['bph']
    this.totalBalance = this.abDatas['balance_w_installments']
    this.totalBalanceNoInstallment = this.abDatas['balance_wo_installments']

    this.bphDatas.forEach(data => {
        this.totalBillAmount += data['total_amount'] ? parseFloat(data['total_amount']) : 0
        this.totalPaidAmount += parseFloat(data['payment_or_credit'] == "" || data['payment_or_credit'] == undefined ? 0 : data['payment_or_credit'])
        if (data['billing_date'].length > 8){
          data['billing_date_parsed'] = data['billing_date']  == '' ? null : data['billing_date'].toString().substr(0,2) + '/' + data['billing_date'].toString().substr(3,2) + '/' + data['billing_date'].toString().substr(6,4)
        }else{
          data['billing_date_parsed'] = data['billing_date']  == '' ? null : data['billing_date'].toString().substr(4,2) + '/' + data['billing_date'].toString().substr(6,2) + '/' + data['billing_date'].toString().substr(0,4)
        }
        data['payments'].forEach(payment => {
          if (payment['payment_or_credit_date'].length > 8){
            payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(0,2) + '/' + payment['payment_or_credit_date'].toString().substr(3,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,4)
          }else{
            payment['payment_or_credit_date_parsed'] = payment['payment_or_credit_date']  == '' ? null : payment['payment_or_credit_date'].toString().substr(4,2) + '/' + payment['payment_or_credit_date'].toString().substr(6,2) + '/' + payment['payment_or_credit_date'].toString().substr(0,4)
          }
        });
        if(data['ipa'] !== undefined){
          data['ipa'].forEach(i => {
            i['paidAmount'] = i['amount'] - i['due_amount']
          })
        }
        this.loading = false
      });
  }

}
