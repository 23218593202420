import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pdf-info',
  templateUrl: './pdf-info.component.html',
  styleUrls: ['./pdf-info.component.scss']
})
export class PdfInfoComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal

  ) { }

  ngOnInit() {
  }

}
